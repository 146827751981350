import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import lodash from "lodash";
/**
 * App routes
 */
import appRoutes from '@/router/routes/app.routes';

Vue.use(VueRouter);

const paymentRequiredPages = [
    'AutoPromotionPage',
    'HashtagGeneratorPage',
    'DirectMessagesPage',
    'SchedulePostingPage',
    'CommentTrackerPage'
];

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes: appRoutes,
});

router.beforeEach((to, from, next) => {

    /**
     * Set page title
     */
    if (to.matched.some(record => record.meta.title)) {
        let title = to.params.product !== undefined ?
            to.params.product.split('-').map(c => lodash.capitalize(c)).join(' ') : to.meta.title;

        document.title = to.params.product !== undefined ? `inSOCIAL: ${ title } ${ to.meta.title }` : `inSOCIAL: ${ title }`;
        next();
    }

    if (to.matched.some(record => record.meta.isPublic)) {
        if (!store.getters['user/isUserAuthenticated']) return next();

        return next({ name: 'DashboardPage' });
    }

    if (to.matched.some(record => record.meta.isPublic)) {
        if (!store.getters['user/isUserAuthenticated']) return next();

        return next({ name: 'DashboardPage' });
    }

    if (to.matched.some(record => record.meta.isPrivate)) {
        if (!store.getters['user/isUserAuthenticated'])
            return next({ name: 'SignInPage', query: { redirect: to.fullPath } });

        if (paymentRequiredPages.includes(to.name)) {
            if (store.getters['app/getProductAccess'].includes(to.name)) return next();
            else return next({ name: store.getters['app/getRedirectPage'], params: { reason: 'not-paid' } });
        } else next();
    }
    return next();
});

export default router;
